import { AppConfig } from '../app/app.config';

export const environment: AppConfig = {
  apiUrl: 'https://finguard-api-dev.primedash.com',
  keycloak: {
    authority: 'https://finguard-auth-dev.primedash.com',
    realm: 'master',
    clientId: 'primedash-finguard',
  },
};
