import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { EChartsOption } from 'echarts';

interface ChartData {
  [key: string]: number;
}

interface ChartLabels {
  [key: string]: string;

  title?: any;
}

interface ChartDataItem {
  value: number;
  name: string;
  itemStyle: {
    color: string;
  };
  percentage: number;
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnChanges {
  @Input() data: ChartData;
  @Input() labels: ChartLabels;

  chartOption: EChartsOption;

  // Define color mappings based on possible keys
  private readonly COLORS: any = {
    approved: '#00E38C',
    underReview: '#FF9900',
    rejected: '#D66C6B',
    paid: '#00E38C',
    overdue: '#D66C6B',
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] || changes['labels']) {
      this.updateChart();
    }
  }

  private calculatePercentage(value: number, total: number): number {
    return total ? Math.round((value / total) * 100) : 0;
  }

  private updateChart(): void {
    if (!this.data || !this.labels) return;

    // Get all keys except 'title' from labels
    const dataKeys = Object.keys(this.labels).filter((key) => key !== 'title');

    // Calculate total
    const total = dataKeys.reduce((sum, key) => sum + (this.data[key] || 0), 0);

    // Create chart data array
    const chartData: ChartDataItem[] = dataKeys.map((key) => ({
      value: this.data[key] || 0,
      name: this.labels[key],
      itemStyle: {
        color: this.COLORS[key] || '#808080', // fallback color if key not found
      },
      percentage: this.calculatePercentage(this.data[key] || 0, total),
    }));

    // Create center label with colored values
    const centerLabel = [
      '{title|' + (this.labels.title || '') + '}',
      dataKeys
        .map(
          (key, index) =>
            '{' +
            key +
            '|' +
            (this.data[key] || 0) +
            '}' +
            (index < dataKeys.length - 1 ? '{separator|/}' : '')
        )
        .join(''),
    ].join('\n');

    // Create rich text styling object
    const rich: any = {
      title: {
        fontSize: 16,
        fontWeight: 'bold',
        padding: [0, 0, 8, 0],
        color: '#333333',
      },
      separator: {
        fontSize: 20,
        fontWeight: 'bold',
        padding: [8, 4, 0, 4],
        color: '#333333',
      },
    };

    // Add styles for each data key
    dataKeys.forEach((key) => {
      rich[key] = {
        fontSize: 20,
        fontWeight: 'bold',
        padding: [8, 0, 0, 0],
        color: this.COLORS[key] || '#808080',
      };
    });

    this.chartOption = {
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          const { name, value, percentage } = params.data;
          return `${name}: ${value} (${percentage}%)`;
        },
      },
      legend: {
        orient: 'horizontal',
        bottom: '5%',
        icon: 'circle',
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 14,
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: centerLabel,
            rich: rich,
          },
          emphasis: {
            label: {
              show: true,
              formatter: centerLabel,
              rich: rich,
            },
          },
          labelLine: {
            show: false,
          },
          data: chartData,
          animation: true,
          animationDuration: 1000,
          animationEasing: 'cubicInOut',
        },
      ],
    };
  }
}
